import React from 'react';

const FireworksIcon: React.FC<{ color?: string }> = ({
  color = 'var(--color-main)',
}) => (
  <svg
    fill="none"
    height="24"
    width="24"
    stroke={color}
    strokeWidth="14"
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 297 297"
  >
    <g>
      <path
        d="M148.5,107.192c5.775,0,10.458-4.683,10.458-10.457V27.715c0-5.774-4.683-10.457-10.458-10.457
		c-5.775,0-10.458,4.683-10.458,10.457v69.021C138.042,102.51,142.725,107.192,148.5,107.192z"
      />
      <path
        d="M284.003,121.099c-1.483,0.371-36.752,9.367-72.407,33.881c-16.092,11.063-29.643,23.516-40.556,37.173
		c11.456-41.19,29.229-70.421,45.15-89.98c28.104-34.527,56.645-47.339,57.236-47.6c5.293-2.283,7.743-8.422,5.471-13.723
		c-2.275-5.309-8.423-7.771-13.732-5.492c-1.327,0.568-32.895,14.382-64.346,52.571c-18.299,22.221-32.863,48.214-43.289,77.258
		c-4.396,12.246-8.037,25.061-10.953,38.374c-0.988-3.556-2.056-7.155-3.21-10.781C122.5,127.199,83.502,84.543,30.588,69.425
		c-5.552-1.587-11.341,1.63-12.928,7.183c-1.587,5.554,1.629,11.341,7.183,12.928c41.964,11.989,73.071,43.184,92.643,92.823
		c-9.217-9.864-19.922-19.019-32.081-27.378c-35.655-24.514-70.923-33.51-72.407-33.881c-5.599-1.399-11.268,2.003-12.674,7.6
		c-1.406,5.598,1.994,11.276,7.59,12.688c0.332,0.084,33.572,8.634,66.146,31.175c38.47,26.621,59.729,60.614,63.387,101.167
		c0.002,0,0.004,0,0.006-0.001c0.565,7.934,0.591,12.678,0.591,12.817c0.004,5.773,4.685,10.451,10.458,10.451
		c5.775,0,10.458-4.683,10.458-10.457c0-46.25,21.526-84.598,63.983-113.978c32.573-22.541,65.814-31.091,66.146-31.175
		c5.598-1.405,8.998-7.079,7.598-12.68C295.284,123.104,289.607,119.693,284.003,121.099z"
      />
      <path
        d="M10.457,189.807c-2.75,0-5.448,1.118-7.394,3.063C1.118,194.816,0,197.514,0,200.264c0,2.751,1.118,5.448,3.063,7.394
		s4.644,3.064,7.394,3.064s5.448-1.119,7.393-3.064c1.956-1.945,3.065-4.643,3.065-7.394c0-2.75-1.109-5.448-3.065-7.394
		C15.905,190.926,13.218,189.807,10.457,189.807z"
      />
      <path
        d="M113.987,20.918c2.76,0,5.448-1.119,7.393-3.064c1.956-1.945,3.064-4.643,3.064-7.394c0-2.75-1.108-5.448-3.064-7.394
		c-1.944-1.945-4.633-3.063-7.393-3.063c-2.75,0-5.448,1.118-7.395,3.063c-1.945,1.945-3.063,4.644-3.063,7.394
		c0,2.751,1.118,5.448,3.063,7.394C108.539,19.799,111.237,20.918,113.987,20.918z"
      />
      <path
        d="M183.007,20.918c2.761,0,5.459-1.119,7.393-3.064c1.956-1.945,3.065-4.643,3.065-7.394c0-2.75-1.109-5.448-3.065-7.394
		c-1.944-1.945-4.632-3.063-7.393-3.063c-2.75,0-5.448,1.118-7.393,3.063c-1.945,1.945-3.064,4.644-3.064,7.394
		c0,2.751,1.118,5.448,3.064,7.394C177.559,19.799,180.257,20.918,183.007,20.918z"
      />
      <path
        d="M89.341,69.62c2.042,2.041,4.718,3.063,7.395,3.063c2.677,0,5.353-1.021,7.395-3.063c4.084-4.084,4.084-10.706,0-14.79
		L86.875,37.575c-4.085-4.083-10.705-4.083-14.79,0c-4.084,4.084-4.084,10.706,0,14.79L89.341,69.62z"
      />
      <path
        d="M86.875,227.38c-4.085-4.083-10.705-4.083-14.79,0c-4.084,4.084-4.084,10.706,0,14.79l17.256,17.255
		c2.042,2.041,4.718,3.063,7.395,3.063c2.677,0,5.353-1.022,7.395-3.063c4.084-4.084,4.084-10.706,0-14.79L86.875,227.38z"
      />
      <path
        d="M192.87,52.365c2.043,2.041,4.718,3.063,7.395,3.063c2.677,0,5.353-1.021,7.396-3.063l17.255-17.255
		c4.084-4.084,4.084-10.706,0-14.79c-4.085-4.083-10.705-4.083-14.79,0L192.87,37.575C188.786,41.659,188.786,48.281,192.87,52.365z
		"
      />
      <path
        d="M210.125,210.125L192.87,227.38c-4.084,4.084-4.084,10.706,0,14.79c2.043,2.041,4.718,3.063,7.395,3.063
		c2.677,0,5.353-1.021,7.396-3.063l17.255-17.255c4.084-4.084,4.084-10.706,0-14.79C220.83,206.042,214.21,206.042,210.125,210.125z
		"
      />
      <path
        d="M244.633,106.596c-1.945,1.945-3.063,4.643-3.063,7.393s1.118,5.448,3.063,7.393c1.945,1.945,4.644,3.064,7.394,3.064
		c2.761,0,5.449-1.119,7.393-3.064c1.955-1.944,3.065-4.643,3.065-7.393s-1.109-5.448-3.065-7.393
		c-1.944-1.946-4.633-3.064-7.393-3.064C249.276,103.532,246.578,104.65,244.633,106.596z"
      />
      <path
        d="M276.079,79.48c0,2.75,1.118,5.448,3.064,7.393c1.946,1.945,4.643,3.064,7.395,3.064c2.75,0,5.448-1.119,7.393-3.064
		c1.956-1.944,3.064-4.643,3.064-7.393c0-2.751-1.108-5.448-3.064-7.395c-1.944-1.945-4.643-3.063-7.393-3.063
		c-2.751,0-5.448,1.118-7.395,3.063C277.197,74.032,276.079,76.729,276.079,79.48z"
      />
      <path
        d="M269.282,189.807c-2.751,0-5.449,1.118-7.395,3.063c-1.945,1.945-3.064,4.644-3.064,7.394c0,2.751,1.118,5.448,3.064,7.394
		c1.945,1.945,4.644,3.064,7.395,3.064c2.76,0,5.448-1.119,7.393-3.064c1.955-1.945,3.064-4.643,3.064-7.394
		c0-2.75-1.109-5.448-3.064-7.394C274.73,190.926,272.042,189.807,269.282,189.807z"
      />
      <path
        d="M148.497,158.958c2.75,0,5.448-1.12,7.393-3.065c1.956-1.944,3.064-4.643,3.064-7.393s-1.108-5.448-3.064-7.394
		c-1.944-1.945-4.632-3.063-7.393-3.063c-2.75,0-5.448,1.118-7.394,3.063c-1.946,1.945-3.064,4.644-3.064,7.394
		s1.118,5.448,3.064,7.393C143.049,157.838,145.747,158.958,148.497,158.958z"
      />
    </g>
  </svg>
);

export default FireworksIcon;
